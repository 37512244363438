<template>
<el-form :model="ruleForm" status-icon  ref="ruleForm" label-width="100px" class="demo-ruleForm">
  <el-form-item label="文章标题" prop="pass">
    <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
  </el-form-item>
  <el-form-item label="文章关键字" prop="checkPass">
    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
  </el-form-item>
  <el-form-item label="文章摘要" prop="age">
    <el-input v-model.number="ruleForm.age"></el-input>
  </el-form-item>
  <el-form-item label="编辑人" prop="name">
    <el-input v-model.number="ruleForm.name"></el-input>
  </el-form-item>
  <el-form-item label="正文" prop="title">
    <el-input type="textarea"
  v-model.number="ruleForm.title ">
</el-input>
  </el-form-item>
  
  <el-form-item>
    <el-button type="primary" >提交</el-button>
    <el-button >重置</el-button>
  </el-form-item>
</el-form>
</template>
<script>
export default {
     data() {
        return {
          ruleForm: {
          pass: '',
          checkPass: '',
          age: '',
          name:'',
         title: ''
          }
        }
     }
}
</script>